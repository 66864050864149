"use client";

import { ChangeEvent, useMemo, useState } from "react";
import { Alert, Box, Stack, Typography } from "@mui/material";
import isNil from "lodash/isNil";
import sortBy from "lodash/sortBy";
import { granularityTimeScaleLookup } from "../highchartsConfig";

import { ChartableStat } from "modules/Topics/util/fetchingFunctions/charts/types";

import { colorFromUnitLabel } from "../util";
import { capitalizeFirstLetter } from "common/util/capitalizeFirstLetter";
import { humanize, titleize } from "common/util/helpers";
import { useIsMobile } from "common/util/hooks/useIsMobile";
import { ENTIRE_GROUP_NAME } from "modules/Topics/util/fetchingFunctions/charts/constants";

import { TopicDataNotAvailableNote } from "modules/Topics/components/TopicDataNotAvailableNote";
import { DynamicLineChart } from "../DynamicLineChart";
import { LineChartProps, LineChartSeries } from "../LineChart";
import InvestigateChartClearSelection from "./InvestigateChartClearSelection";
import InvestigateChartTitles from "./InvestigateChartTitles";
import { InvestigateDropdown } from "./InvestigateDropdown";

interface MappedDropdownOption extends Omit<ChartableStat, "id"> {
  value: string;
  group?: string | null;
}

export interface IndicatorInvestigateChartProps extends Omit<LineChartProps, "series"> {
  title: string;
  nameOfSection?: string;
  timeRange?: string;
  locationName?: string;
  unitLabel?: string | null | undefined;
  suppressionNote?: React.ReactNode;
  indicators: ChartableStat[];
  description?: string;
  indicatorGroups?: Record<string, string[]>;
  maxPadding?: number;
  hideClearSelection?: boolean;
}

export const IndicatorInvestigateChart: React.FC<IndicatorInvestigateChartProps> = ({
  title,
  timeRange,
  locationName,
  indicators,
  indicatorGroups,
  unitLabel,
  suppressionNote,
  nameOfSection,
  description,
  maxPadding = 5,
  hideClearSelection = false,
  ...props
}) => {
  const isMobile = useIsMobile("lg");

  const mappedOptions = useMemo(() => {
    const options: Record<string, MappedDropdownOption> = {};
    indicators.forEach(
      ({ id, ..._props }) =>
        (options[id] = {
          value: id,
          ..._props
        })
    );
    return options;
  }, [indicators]);

  const [selectedOption, setSelectedOption] = useState<MappedDropdownOption | undefined>(
    mappedOptions[Object.keys(mappedOptions)[0] as string] as MappedDropdownOption
  );

  const inputOptions = useMemo(() => {
    const options = sortBy(
      indicators.map(({ id, title, group }) => ({
        value: id,
        title: capitalizeFirstLetter(title),
        group
      })),
      (obj) => {
        const firstChar = obj.title.charAt(0);
        const groupType =
          obj.title.split("(").length > 0 ? obj.title.split("(")[1]?.charAt(0) ?? "" : "";
        return `${obj.title}${firstChar}${groupType}`;
      }
    );
    const id = options[0]?.value;
    if (!isNil(id)) {
      const option = mappedOptions[id];
      setSelectedOption(option);
    }
    return options;
  }, [indicators, mappedOptions]);

  // Stratifications
  const mappedStratificationOptions = useMemo(() => {
    const options: Record<string, MappedDropdownOption> = {};
    if (!selectedOption?.stratificationGroups) return options;
    selectedOption?.stratificationGroups.forEach(
      ({ id, ..._props }) =>
        (options[id] = {
          value: id,
          ..._props
        })
    );
    return options;
  }, [selectedOption]);

  const stratificationInputOptions = useMemo(() => {
    if (!selectedOption?.stratificationGroups) return [];
    return sortBy(
      selectedOption.stratificationGroups.map(({ id, title }) => ({ value: id, title })),
      ({ title }) => title
    );
  }, [selectedOption]);

  const [selectedStratificationOption, setSelectedStratificationOption] =
    useState<MappedDropdownOption | null>(null);

  const setNewStratificationOption = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedStratificationOption(
      mappedStratificationOptions[event.target.value] as MappedDropdownOption
    );
  };

  const setNewOption = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(mappedOptions[event.target.value] as MappedDropdownOption);
    setSelectedStratificationOption(null);
  };

  const {
    title: optionTitle = "",
    group: optionGroup = "",
    value: optionValue,
    unitLabel: optionUnitLabel = ""
  } = selectedOption || {};

  const series = useMemo(() => {
    const mainSeries = selectedOption?.series ?? [];
    let series = mainSeries;
    if (selectedStratificationOption?.series?.length) {
      const updatedMainSeries: LineChartSeries[] = mainSeries.map((s) => ({
        ...s,
        name: ENTIRE_GROUP_NAME
      }));
      // Update series with colors, etc
      const selectedSeries = selectedStratificationOption?.series.map((s) => ({
        ...s,
        visible: true,
        color: colorFromUnitLabel(s.name).hex
      }));
      series = [...updatedMainSeries, ...selectedSeries];
    }
    return series;
  }, [selectedOption?.series, selectedStratificationOption?.series]);

  const chartProps = useMemo(() => {
    const currentGranularity = selectedOption?.granularity ?? props.granularity;
    let maxValue = Math.max(
      ...(series.flatMap(({ values }) => values).filter((v) => v) as number[])
    );
    maxValue && (maxValue += maxPadding);
    return {
      ...props,
      precision: selectedOption?.precision ?? props.precision,
      percent: selectedOption?.isPercent ?? props.percent,
      statCaption: selectedStratificationOption?.statCaption ?? selectedOption?.statCaption ?? "",
      granularity: currentGranularity,
      timeScale: granularityTimeScaleLookup[currentGranularity ?? "year"],
      xAxisTitle: titleize(humanize(currentGranularity ?? "year")),
      yAxisOptions: {
        ...props.yAxisOptions,
        max: maxValue
      },
      yAxisTitleText: selectedOption?.yAxisTitleText ?? props.yAxisTitleText
    };
  }, [
    selectedOption?.granularity,
    selectedOption?.precision,
    selectedOption?.isPercent,
    selectedOption?.statCaption,
    selectedOption?.yAxisTitleText,
    props,
    series,
    maxPadding,
    selectedStratificationOption?.statCaption
  ]);

  if (indicators.every((indicator) => indicator.series?.every(({ values }) => !values.length))) {
    return (
      <Alert severity="warning">
        This section is not shown because there are insufficient data
      </Alert>
    );
  }

  const chartHasData = series?.every((s) => s.values.length > 0);

  return (
    <Stack spacing={2}>
      <Stack spacing={4}>
        <Stack
          direction={{ xs: "column", lg: "row" }}
          gap={2}
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          <InvestigateDropdown
            value={optionValue}
            title="Select indicator"
            onChange={setNewOption}
            grouped={true}
            options={inputOptions}
            valueIsId={true}
            type="Chart"
            preventGrowth
          />
          {stratificationInputOptions?.length > 0 && (
            <Box
              display="flex"
              gap={isMobile ? 2 : 1}
              sx={{
                flex: { xs: 1, lg: 0 },
                alignItems: { xs: undefined, lg: "flex-end" },
                flexWrap: { xs: "wrap", lg: "nowrap" }
              }}
            >
              {!isMobile && (
                <Typography mr={1} mb={2} fontWeight={600}>
                  and
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  gap: 2,
                  flex: 1
                }}
              >
                <InvestigateDropdown
                  title={
                    <Typography component="span" fontWeight="inherit">
                      Select stratification <Typography component="span">(optional)</Typography>
                    </Typography>
                  }
                  placeholder="Select stratification"
                  value={selectedStratificationOption?.value ?? ""}
                  onChange={setNewStratificationOption}
                  options={stratificationInputOptions}
                  valueIsId={true}
                  type="Chart"
                  preventGrowth
                />

                {selectedStratificationOption && hideClearSelection === false && (
                  <InvestigateChartClearSelection
                    handleClick={() => setSelectedStratificationOption(null)}
                  />
                )}
              </Box>
            </Box>
          )}
        </Stack>
        <InvestigateChartTitles
          title={optionTitle}
          groupTitle={optionGroup}
          stratificationTitle={selectedStratificationOption?.title}
          subtitle={optionUnitLabel || unitLabel}
          locationName={locationName}
        />
        {!chartHasData && (
          <Stack sx={{ minHeight: 400, alignItems: "center", justifyContent: "center" }}>
            <TopicDataNotAvailableNote />
          </Stack>
        )}
        {chartHasData && (
          <DynamicLineChart
            title={selectedOption?.title ?? ""}
            markersOn
            {...chartProps}
            series={series}
            useNavigator={chartProps.useNavigator && series?.every((s) => s.values.length > 6)}
            useScrollbar={chartProps.useScrollbar && series?.every((s) => s.values.length > 6)}
            pointCountToEnableZoom={20}
          />
        )}
        {suppressionNote && (
          <Alert severity="info" sx={{ mt: 2 }}>
            {suppressionNote}
          </Alert>
        )}
      </Stack>
    </Stack>
  );
};
